<template>
    <section class="shop-series">
        <tool-bar v-bind:lang.sync="lang">
            Product Series
            <template slot="buttons">
                <div class="level-item">
                    <router-link class="button has-text-black"
                                 v-bind:to="{ name: 'add-product-series' }">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add a Series</span>
                    </router-link>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': isLoading }"
                            v-bind:disabled="!isEdited"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <div class="content">
            <article class="message is-dark">
                <div class="message-body" style="background-color: rgba(255, 255, 255, 1)">
                    You can drag the treatment series below to reorder them. It will be reflected in the main website upon
                    saving.
                </div>
            </article>

            <div class="series-table">
                <section class="section"
                         v-if="series.length === 0">
                    <div class="content has-text-grey has-text-centered">
                        <h1>
                            <font-awesome-icon icon="empty-set"></font-awesome-icon>
                        </h1>
                        <p>Nothing here yet. </p>
                        <router-link class="button is-text"
                                     v-bind:to="{ name: 'add-product-series' }">
                            Create a series?
                        </router-link>
                    </div>
                </section>

                <draggable class="columns is-marginless"
                           style="flex-wrap: wrap;"
                           v-model="series"
                           v-on:end="onDragEnd">
                    <div class="column is-12 is-6-tablet is-4-widescreen"
                         v-for="aSeries in series"
                         v-bind:key="aSeries._id">
                        <series-card v-bind:lang="lang"
                                     v-bind:series="aSeries">
                        </series-card>
                    </div>
                </draggable>
            </div>
        </div>
    </section>
</template>

<script>
import draggable from "vuedraggable";

import ToolBar from "../layouts/ToolBar";
import SeriesCard from "./Series/SeriesCard";

export default {
    name: "ShopCategories",
    components: {
        draggable,
        SeriesCard,
        ToolBar,
    },
    data() {
        return {
            sortableOptions: {
                chosenClass: "is-selected"
            },

            isLoading: false,
            isEdited: false,

            categoryModalMode: "add",

            lang: "en",

            series: []
        }
    },
    computed: {
        shop () {
            return this.$store.state.shop;
        },
    },
    methods: {
        onDragEnd() {
            this.isEdited = true;
        },

        async save () {
            this.isLoading = true;
            try {
                await this.$store.dispatch(
                    "shop/updateShop",
                    {
                        "series": this.series,
                    }
                );
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
        }
    },
    mounted() {
        this.series = this.shop.series;
    },
    watch: {
        "shop": {
            handler: function (newVal) {
                if (newVal.series) {
                    this.series = newVal.series
                }
            },
            deep: true
        },

        "series"(newVal, oldVal) {
            if (oldVal.length) {
                console.log(newVal);
                this.isEdited = true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    margin: 1rem;

    .shop-series {
        position: relative;
        background-color: rgba(245, 245, 245, 1);

        .button.has-text-black {
            text-decoration: none;
        }

        & > .message {
            margin-left: 25px;
            margin-right: 25px;

        }

        & > .series-table {
            margin: 1.5rem;
            padding-top: 1rem;
            padding-bottom: 1rem;

            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
</style>
