<template>
    <div class="card product-series">
        <div class="card-image">
            <figure class="image is-3by1 is-marginless">
                <img v-bind:alt="series.title[lang]"
                     v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/series/image/${ series.cover }`">
            </figure>
            <div class="titles"
                 v-if="series.headlineColor"
                 v-bind:style="{ 'color': `rgba(${ series.headlineColor.r }, ${ series.headlineColor.g }, ${ series.headlineColor.b }, ${ series.headlineColor.a }) !important` }">
                <h4 v-html="series.title[lang]"></h4>
                <h5 v-model="series.subtitle[lang]"></h5>
            </div>
            <div class="titles"
                 v-else>
                <h4 v-html="series.title[lang]"></h4>
                <h5 v-model="series.subtitle[lang]"></h5>
            </div>
        </div>
        <div class="card-content">
            <div class="media">
                <div class="media-content">
                    <p class="title is-4"
                       v-html="series.title[lang]">
                    </p>
                    <p class="subtitle is-6"
                       v-html="series.subtitle[lang]">
                    </p>
                </div>
                <div class="media-right">
                    <router-link class="button is-medium"
                                 style="margin-right: 10px; "
                                 v-bind:to="{ name: 'edit-product-series', params: { seriesId: series._id } }">
                        <font-awesome-icon icon="cog"></font-awesome-icon>
                    </router-link>
                    <button class="button is-medium is-danger is-outlined"
                            v-bind:class="{ 'is-loading': isLoading }"
                            v-on:click="deleteSeries">
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SeriesCard",
        props: {
            lang: {
                type: String,
                required: true,
            },

            series: {
                type: Object,
                required: true,
            },
        },
        data () {
            return {
                isLoading: false,
            };
        },
        computed: {

        },
        methods: {
            async deleteSeries () {
                const {
                    _id,
                } = this.series;

                this.isLoading = true;
                try {
                    await this.$store
                        .dispatch(
                            "shop/deleteSeries",
                            _id
                        );
                } catch (e) {
                    this.isLoading = false;
                    console.error(e);
                    return;
                }
                this.isLoading = false
            },
        },
    }
</script>

<style lang="scss" scoped>
    .product-series {
        height: 100%;
        margin-bottom: 1rem;

        .card-image {
            position: relative;

            & > .titles {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                margin: 2rem;

                h4 {
                    font-weight: bold;
                    font-size: 24px;
                }

                h5 {
                    font-weight: normal;
                    font-size: 16px;
                }
            }
        }

        .card-content {
            & > .media {
                & > .media-right {
                    button {
                        &:first-child {
                            margin-right: 10px;
                        }
                    }
                }
            }

        }
    }
</style>
